import { render, staticRenderFns } from "./MaskProcess.vue?vue&type=template&id=892b8852"
import script from "./MaskProcess.vue?vue&type=script&lang=js"
export * from "./MaskProcess.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports