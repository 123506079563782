<template>
  <div id="maskProcess">
    <el-dialog
      :title="maskProcessFormTitle"
      width="1200px"
      :visible.sync="maskProcessDialogVisible"
      :close-on-click-modal="false"
      @close="maskProcessDialogClose"
    >
      <el-form
        ref="maskProcessFormRef"
        :model="maskProcessForm"
        :rules="maskProcessFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="maskProcessForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="型号规格" prop="spec">
              <el-input v-model="maskProcessForm.spec" placeholder="请输入型号规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="生产批号" prop="batchNo">
              <el-input v-model="maskProcessForm.batchNo" placeholder="请输入生产批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="抽检数量" prop="samplingQuantity">
              <el-input v-model="maskProcessForm.samplingQuantity" placeholder="请输入抽检数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="口罩体裁切：长度17.5±0.8cm检验结果" label-width="300px" />
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检1" prop="lengthResult1">
              <el-input v-model="maskProcessForm.lengthResult1" placeholder="请输入巡检1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检2" prop="lengthResult2">
              <el-input v-model="maskProcessForm.lengthResult2" placeholder="请输入巡检2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检3" prop="lengthResult3">
              <el-input v-model="maskProcessForm.lengthResult3" placeholder="请输入巡检3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检4" prop="lengthResult4">
              <el-input v-model="maskProcessForm.lengthResult4" placeholder="请输入巡检4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结论" prop="lengthConclusion">
              <el-radio-group v-model="maskProcessForm.lengthConclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="口罩体裁切：宽度9.5±0.4cm检验结果" label-width="290px" />
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检1" prop="widthResult1">
              <el-input v-model="maskProcessForm.widthResult1" placeholder="请输入巡检1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检2" prop="widthResult2">
              <el-input v-model="maskProcessForm.widthResult2" placeholder="请输入巡检2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检3" prop="widthResult3">
              <el-input v-model="maskProcessForm.widthResult3" placeholder="请输入巡检3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检4" prop="widthResult4">
              <el-input v-model="maskProcessForm.widthResult4" placeholder="请输入巡检4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结论" prop="widthConclusion">
              <el-radio-group v-model="maskProcessForm.widthConclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="口罩体裁切：鼻夹长度10.5±1cm检验结果" label-width="315px" />
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检1" prop="noseResult1">
              <el-input v-model="maskProcessForm.noseResult1" placeholder="请输入巡检1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检2" prop="noseResult2">
              <el-input v-model="maskProcessForm.noseResult2" placeholder="请输入巡检2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检3" prop="noseResult3">
              <el-input v-model="maskProcessForm.noseResult3" placeholder="请输入巡检3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检4" prop="noseResult4">
              <el-input v-model="maskProcessForm.noseResult4" placeholder="请输入巡检4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结论" prop="noseConclusion">
              <el-radio-group v-model="maskProcessForm.noseConclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="口罩体裁切：切边平整无挂边料，生产过程不跑偏检验结果" label-width="415px" />
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检1" prop="trimmingResult1">
              <el-input v-model="maskProcessForm.trimmingResult1" placeholder="请输入巡检1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检2" prop="trimmingResult2">
              <el-input v-model="maskProcessForm.trimmingResult2" placeholder="请输入巡检2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检3" prop="trimmingResult3">
              <el-input v-model="maskProcessForm.trimmingResult3" placeholder="请输入巡检3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检4" prop="trimmingResult4">
              <el-input v-model="maskProcessForm.trimmingResult4" placeholder="请输入巡检4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结论" prop="trimmingConclusion">
              <el-radio-group v-model="maskProcessForm.trimmingConclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="口罩体裁切：无褶皱平整，内置原料无明显褶皱检验结果" label-width="400px" />
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检1" prop="foldingResult1">
              <el-input v-model="maskProcessForm.foldingResult1" placeholder="请输入巡检1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检2" prop="foldingResult2">
              <el-input v-model="maskProcessForm.foldingResult2" placeholder="请输入巡检2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检3" prop="foldingResult3">
              <el-input v-model="maskProcessForm.foldingResult3" placeholder="请输入巡检3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检4" prop="foldingResult4">
              <el-input v-model="maskProcessForm.foldingResult4" placeholder="请输入巡检4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结论" prop="foldingConclusion">
              <el-radio-group v-model="maskProcessForm.foldingConclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="口罩体裁切：内胆层次无缺损检验结果" label-width="290px" />
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检1" prop="innerResult1">
              <el-input v-model="maskProcessForm.innerResult1" placeholder="请输入巡检1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检2" prop="innerResult2">
              <el-input v-model="maskProcessForm.innerResult2" placeholder="请输入巡检2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检3" prop="innerResult3">
              <el-input v-model="maskProcessForm.innerResult3" placeholder="请输入巡检3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检4" prop="innerResult4">
              <el-input v-model="maskProcessForm.innerResult4" placeholder="请输入巡检4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结论" prop="innerConclusion">
              <el-radio-group v-model="maskProcessForm.innerConclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="口罩体裁切：形状完整，表面应无破损、污渍检验结果" label-width="385px" />
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检1" prop="shapeResult1">
              <el-input v-model="maskProcessForm.shapeResult1" placeholder="请输入巡检1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检2" prop="shapeResult2">
              <el-input v-model="maskProcessForm.shapeResult2" placeholder="请输入巡检2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检3" prop="shapeResult3">
              <el-input v-model="maskProcessForm.shapeResult3" placeholder="请输入巡检3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检4" prop="shapeResult4">
              <el-input v-model="maskProcessForm.shapeResult4" placeholder="请输入巡检4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结论" prop="shapeConclusion">
              <el-radio-group v-model="maskProcessForm.shapeConclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="口罩体裁切：口罩体连接点的断裂强度应大于10N检验结果" label-width="415px" />
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检1" prop="spotWeldingResult1">
              <el-input v-model="maskProcessForm.spotWeldingResult1" placeholder="请输入巡检1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检2" prop="spotWeldingResult2">
              <el-input v-model="maskProcessForm.spotWeldingResult2" placeholder="请输入巡检2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检3" prop="spotWeldingResult3">
              <el-input v-model="maskProcessForm.spotWeldingResult3" placeholder="请输入巡检3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检4" prop="spotWeldingResult4">
              <el-input v-model="maskProcessForm.spotWeldingResult4" placeholder="请输入巡检4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结论" prop="spotWeldingConclusion">
              <el-radio-group v-model="maskProcessForm.spotWeldingConclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="包装：绕带不凌乱检验结果" label-width="215px" />
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检1" prop="packingResult1">
              <el-input v-model="maskProcessForm.packingResult1" placeholder="请输入巡检1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检2" prop="packingResult2">
              <el-input v-model="maskProcessForm.packingResult2" placeholder="请输入巡检2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检3" prop="packingResult3">
              <el-input v-model="maskProcessForm.packingResult3" placeholder="请输入巡检3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检4" prop="packingResult4">
              <el-input v-model="maskProcessForm.packingResult4" placeholder="请输入巡检4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结论" prop="packingConclusion">
              <el-radio-group v-model="maskProcessForm.packingConclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="封口：封口表面无褶皱、开口检验结果" label-width="285px" />
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检1" prop="sealResult1">
              <el-input v-model="maskProcessForm.sealResult1" placeholder="请输入巡检1" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检2" prop="sealResult2">
              <el-input v-model="maskProcessForm.sealResult2" placeholder="请输入巡检2" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检3" prop="sealResult3">
              <el-input v-model="maskProcessForm.sealResult3" placeholder="请输入巡检3" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检4" prop="sealResult4">
              <el-input v-model="maskProcessForm.sealResult4" placeholder="请输入巡检4" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结论" prop="sealConclusion">
              <el-radio-group v-model="maskProcessForm.sealConclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="maskProcessForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="检验人" prop="checker">
              <el-input v-model="maskProcessForm.checker" placeholder="请输入检验人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="检验人日期" prop="checkerDate">
              <el-date-picker v-model="maskProcessForm.checkerDate" placeholder="请选择检验人日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="maskProcessForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="复核人日期" prop="reviewerDate">
              <el-date-picker v-model="maskProcessForm.reviewerDate" placeholder="请选择复核人日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检批号" prop="inspectionNo">
              <el-input v-model="maskProcessForm.inspectionNo" placeholder="请输入巡检批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检次序" prop="inspectionOrder">
              <el-input v-model="maskProcessForm.inspectionOrder" placeholder="请输入巡检次序" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检时间" prop="inspectionTime">
              <el-date-picker v-model="maskProcessForm.inspectionTime" placeholder="请选择巡检时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检人员" prop="inspectionPersonnel">
              <el-input v-model="maskProcessForm.inspectionPersonnel" placeholder="请输入巡检人员" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="抽查样品">
          <vxe-toolbar v-if="maskProcessFormTitle !== '一次性使用医用口罩过程检验记录详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="maskProcessDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="maskLength"
              title="口罩体长度"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="maskWidth"
              title="口罩体宽度"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="noseLength"
              title="鼻夹长度"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="jointStrength"
              title="口罩体连接点的断裂强度"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column v-if="maskProcessFormTitle !== '一次性使用医用口罩过程检验记录详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="maskProcessDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="maskProcessFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="maskProcessPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="batchNo" label="生产批号" />
      <el-table-column prop="samplingQuantity" label="抽检数量" />
      <el-table-column prop="lengthResult1" label="口罩体裁切：长度17.5±0.8cm检验结果：巡检1" />
      <el-table-column prop="lengthResult2" label="巡检2" />
      <el-table-column prop="lengthResult3" label="巡检3" />
      <el-table-column prop="lengthResult4" label="巡检4" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.lengthConclusion === 0">不合格</span>
          <span v-if="scope.row.lengthConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="widthResult1" label="口罩体裁切：宽度9.5±0.4cm检验结果：巡检1" />
      <el-table-column prop="widthResult2" label="巡检2" />
      <el-table-column prop="widthResult3" label="巡检3" />
      <el-table-column prop="widthResult4" label="巡检4" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.widthConclusion === 0">不合格</span>
          <span v-if="scope.row.widthConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="noseResult1" label="口罩体裁切：鼻夹长度10.5±1cm检验结果：巡检1" />
      <el-table-column prop="noseResult2" label="巡检2" />
      <el-table-column prop="noseResult3" label="巡检3" />
      <el-table-column prop="noseResult4" label="巡检4" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.noseConclusion === 0">不合格</span>
          <span v-if="scope.row.noseConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="trimmingResult1" label="口罩体裁切：切边平整无挂边料，生产过程不跑偏检验结果：巡检1" />
      <el-table-column prop="trimmingResult2" label="巡检2" />
      <el-table-column prop="trimmingResult3" label="巡检3" />
      <el-table-column prop="trimmingResult4" label="巡检4" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.trimmingConclusion === 0">不合格</span>
          <span v-if="scope.row.trimmingConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="foldingResult1" label="口罩体裁切：无褶皱平整，内置原料无明显褶皱检验结果：巡检1" />
      <el-table-column prop="foldingResult2" label="巡检2" />
      <el-table-column prop="foldingResult3" label="巡检3" />
      <el-table-column prop="foldingResult4" label="巡检4" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.foldingConclusion === 0">不合格</span>
          <span v-if="scope.row.foldingConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="innerResult1" label="口罩体裁切：内胆层次无缺损检验结果：巡检1" />
      <el-table-column prop="innerResult2" label="巡检2" />
      <el-table-column prop="innerResult3" label="巡检3" />
      <el-table-column prop="innerResult4" label="巡检4" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.innerConclusion === 0">不合格</span>
          <span v-if="scope.row.innerConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="shapeResult1" label="口罩体裁切：形状完整，表面应无破损、污渍检验结果：巡检1" />
      <el-table-column prop="shapeResult2" label="巡检2" />
      <el-table-column prop="shapeResult3" label="巡检3" />
      <el-table-column prop="shapeResult4" label="巡检4" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.shapeConclusion === 0">不合格</span>
          <span v-if="scope.row.shapeConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="spotWeldingResult1" label="口罩带点焊：口罩体连接点的断裂强度应大于10N检验结果：巡检1" />
      <el-table-column prop="spotWeldingResult2" label="巡检2" />
      <el-table-column prop="spotWeldingResult3" label="巡检3" />
      <el-table-column prop="spotWeldingResult4" label="巡检4" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.spotWeldingConclusion === 0">不合格</span>
          <span v-if="scope.row.spotWeldingConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="packingResult1" label="包装：绕带不凌乱检验结果：巡检1" />
      <el-table-column prop="packingResult2" label="巡检2" />
      <el-table-column prop="packingResult3" label="巡检3" />
      <el-table-column prop="packingResult4" label="巡检4" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.packingConclusion === 0">不合格</span>
          <span v-if="scope.row.packingConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="sealResult1" label="封口：封口表面无褶皱、开口检验结果：巡检1" />
      <el-table-column prop="sealResult2" label="巡检2" />
      <el-table-column prop="sealResult3" label="巡检3" />
      <el-table-column prop="sealResult4" label="巡检4" />
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.sealConclusion === 0">不合格</span>
          <span v-if="scope.row.sealConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="checker" label="检验人" />
      <el-table-column label="检验人日期">
        <template slot-scope="scope">
          <span v-if="scope.row.checkerDate">{{ scope.row.checkerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="复核人日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="inspectionNo" label="巡检批号" />
      <el-table-column prop="inspectionOrder" label="巡检次序" />
      <el-table-column label="巡检时间">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionTime">{{ scope.row.inspectionTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="inspectionPersonnel" label="巡检人员" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="maskProcessPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMaskProcess, deleteMaskProcess, updateMaskProcess, selectMaskProcessInfo, selectMaskProcessList } from '@/api/quality/maskProcess'

export default {
  data () {
    return {
      maskProcessDialogVisible: false,
      maskProcessFormTitle: '',
      maskProcessForm: {
        id: '',
        productName: '',
        spec: '',
        batchNo: '',
        samplingQuantity: '',
        lengthResult1: '',
        lengthResult2: '',
        lengthResult3: '',
        lengthResult4: '',
        lengthConclusion: '',
        widthResult1: '',
        widthResult2: '',
        widthResult3: '',
        widthResult4: '',
        widthConclusion: '',
        noseResult1: '',
        noseResult2: '',
        noseResult3: '',
        noseResult4: '',
        noseConclusion: '',
        trimmingResult1: '',
        trimmingResult2: '',
        trimmingResult3: '',
        trimmingResult4: '',
        trimmingConclusion: '',
        foldingResult1: '',
        foldingResult2: '',
        foldingResult3: '',
        foldingResult4: '',
        foldingConclusion: '',
        innerResult1: '',
        innerResult2: '',
        innerResult3: '',
        innerResult4: '',
        innerConclusion: '',
        shapeResult1: '',
        shapeResult2: '',
        shapeResult3: '',
        shapeResult4: '',
        shapeConclusion: '',
        spotWeldingResult1: '',
        spotWeldingResult2: '',
        spotWeldingResult3: '',
        spotWeldingResult4: '',
        spotWeldingConclusion: '',
        packingResult1: '',
        packingResult2: '',
        packingResult3: '',
        packingResult4: '',
        packingConclusion: '',
        sealResult1: '',
        sealResult2: '',
        sealResult3: '',
        sealResult4: '',
        sealConclusion: '',
        remarks: '',
        checker: '',
        checkerDate: '',
        reviewer: '',
        reviewerDate: '',
        inspectionNo: '',
        inspectionOrder: '',
        inspectionTime: '',
        inspectionPersonnel: '',
        maskProcessDetailJson: ''
      },
      maskProcessFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      maskProcessPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      },
      maskProcessDetailList: []
    }
  },
  created () {
    selectMaskProcessList(this.searchForm).then(res => {
      this.maskProcessPage = res
    })
  },
  methods: {
    maskProcessDialogClose () {
      this.$refs.maskProcessFormRef.resetFields()
      this.maskProcessDetailList = []
    },
    maskProcessFormSubmit () {
      if (this.maskProcessFormTitle === '一次性使用医用口罩过程检验记录详情') {
        this.maskProcessDialogVisible = false
        return
      }
      this.$refs.maskProcessFormRef.validate(async valid => {
        if (valid) {
          this.maskProcessForm.maskProcessDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.maskProcessFormTitle === '新增一次性使用医用口罩过程检验记录') {
            await addMaskProcess(this.maskProcessForm)
          } else if (this.maskProcessFormTitle === '修改一次性使用医用口罩过程检验记录') {
            await updateMaskProcess(this.maskProcessForm)
          }
          this.maskProcessPage = await selectMaskProcessList(this.searchForm)
          this.maskProcessDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.maskProcessFormTitle = '新增一次性使用医用口罩过程检验记录'
      this.maskProcessDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaskProcess(row.id)
        if (this.maskProcessPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.maskProcessPage = await selectMaskProcessList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.maskProcessFormTitle = '修改一次性使用医用口罩过程检验记录'
      this.maskProcessDialogVisible = true
      this.selectMaskProcessInfoById(row.id)
    },
    handleInfo (index, row) {
      this.maskProcessFormTitle = '一次性使用医用口罩过程检验记录详情'
      this.maskProcessDialogVisible = true
      this.selectMaskProcessInfoById(row.id)
    },
    selectMaskProcessInfoById (id) {
      selectMaskProcessInfo(id).then(res => {
        this.maskProcessForm.id = res.id
        this.maskProcessForm.productName = res.productName
        this.maskProcessForm.spec = res.spec
        this.maskProcessForm.batchNo = res.batchNo
        this.maskProcessForm.samplingQuantity = res.samplingQuantity
        this.maskProcessForm.lengthResult1 = res.lengthResult1
        this.maskProcessForm.lengthResult2 = res.lengthResult2
        this.maskProcessForm.lengthResult3 = res.lengthResult3
        this.maskProcessForm.lengthResult4 = res.lengthResult4
        this.maskProcessForm.lengthConclusion = res.lengthConclusion
        this.maskProcessForm.widthResult1 = res.widthResult1
        this.maskProcessForm.widthResult2 = res.widthResult2
        this.maskProcessForm.widthResult3 = res.widthResult3
        this.maskProcessForm.widthResult4 = res.widthResult4
        this.maskProcessForm.widthConclusion = res.widthConclusion
        this.maskProcessForm.noseResult1 = res.noseResult1
        this.maskProcessForm.noseResult2 = res.noseResult2
        this.maskProcessForm.noseResult3 = res.noseResult3
        this.maskProcessForm.noseResult4 = res.noseResult4
        this.maskProcessForm.noseConclusion = res.noseConclusion
        this.maskProcessForm.trimmingResult1 = res.trimmingResult1
        this.maskProcessForm.trimmingResult2 = res.trimmingResult2
        this.maskProcessForm.trimmingResult3 = res.trimmingResult3
        this.maskProcessForm.trimmingResult4 = res.trimmingResult4
        this.maskProcessForm.trimmingConclusion = res.trimmingConclusion
        this.maskProcessForm.foldingResult1 = res.foldingResult1
        this.maskProcessForm.foldingResult2 = res.foldingResult2
        this.maskProcessForm.foldingResult3 = res.foldingResult3
        this.maskProcessForm.foldingResult4 = res.foldingResult4
        this.maskProcessForm.foldingConclusion = res.foldingConclusion
        this.maskProcessForm.innerResult1 = res.innerResult1
        this.maskProcessForm.innerResult2 = res.innerResult2
        this.maskProcessForm.innerResult3 = res.innerResult3
        this.maskProcessForm.innerResult4 = res.innerResult4
        this.maskProcessForm.innerConclusion = res.innerConclusion
        this.maskProcessForm.shapeResult1 = res.shapeResult1
        this.maskProcessForm.shapeResult2 = res.shapeResult2
        this.maskProcessForm.shapeResult3 = res.shapeResult3
        this.maskProcessForm.shapeResult4 = res.shapeResult4
        this.maskProcessForm.shapeConclusion = res.shapeConclusion
        this.maskProcessForm.spotWeldingResult1 = res.spotWeldingResult1
        this.maskProcessForm.spotWeldingResult2 = res.spotWeldingResult2
        this.maskProcessForm.spotWeldingResult3 = res.spotWeldingResult3
        this.maskProcessForm.spotWeldingResult4 = res.spotWeldingResult4
        this.maskProcessForm.spotWeldingConclusion = res.spotWeldingConclusion
        this.maskProcessForm.packingResult1 = res.packingResult1
        this.maskProcessForm.packingResult2 = res.packingResult2
        this.maskProcessForm.packingResult3 = res.packingResult3
        this.maskProcessForm.packingResult4 = res.packingResult4
        this.maskProcessForm.packingConclusion = res.packingConclusion
        this.maskProcessForm.sealResult1 = res.sealResult1
        this.maskProcessForm.sealResult2 = res.sealResult2
        this.maskProcessForm.sealResult3 = res.sealResult3
        this.maskProcessForm.sealResult4 = res.sealResult4
        this.maskProcessForm.sealConclusion = res.sealConclusion
        this.maskProcessForm.remarks = res.remarks
        this.maskProcessForm.checker = res.checker
        this.maskProcessForm.checkerDate = res.checkerDate
        this.maskProcessForm.reviewer = res.reviewer
        this.maskProcessForm.reviewerDate = res.reviewerDate
        this.maskProcessForm.inspectionNo = res.inspectionNo
        this.maskProcessForm.inspectionOrder = res.inspectionOrder
        this.maskProcessForm.inspectionTime = res.inspectionTime
        this.maskProcessForm.inspectionPersonnel = res.inspectionPersonnel
        this.maskProcessDetailList = res.maskProcessDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaskProcessList(this.searchForm).then(res => {
        this.maskProcessPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaskProcessList(this.searchForm).then(res => {
        this.maskProcessPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaskProcessList(this.searchForm).then(res => {
        this.maskProcessPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
